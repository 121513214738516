import { Turbo } from '@hotwired/turbo-rails';

Turbo.setProgressBarDelay(100);

// Until https://github.com/hotwired/turbo-rails/pull/367 is merged
if (typeof Turbo !== 'undefined') {
  Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target);
  };
}
